<template>
    <span>
        <span>Dates: </span>
        <span>{{ getDates(validFrom, validTo) }}</span>
    </span>
</template>

<script>
export default {
    props: {
        validFrom: String,
        validTo: String,
    },
    methods: {
        getDates(validFrom, validTo) {
            const dateFrom = new Date(validFrom);
            const dateTo = new Date(validTo);

            if (dateFrom.getMonth() !== dateTo.getMonth()) {
                return `${this.formatDate(dateFrom)} - ${this.formatDate(dateTo)}`;
            }

            if (dateFrom.getDate() !== dateTo.getDate()) {
                return `${dateFrom.getDate()} - ${this.formatDate(dateTo)}`
            }

            return this.formatDate(dateFrom);
        },

        formatDate(date) {
            const day = date.getDate().toString().padStart(2, 0);
            const month = (date.getMonth() + 1).toString().padStart(2, 0);
            const year = date.getFullYear().toString();
            return `${day}.${month}.${year}`
        }
    }  
};
</script>

<style>

</style>